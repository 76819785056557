<div class='HomePage'>
    <div class='container'>
  
      <div class='row justify-content-center google'>
        <div class='col-12'>
          <a href='https://www.google.com'>
            <img src='https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png' alt='' />
          </a>
        </div>
      </div>
  
      <div class='row justify-content-center text-center'>
        <div *ngFor="let item of items; let i = index" class='card col-4'>
          <a [href]="item.link" [id]="i.toString()">
            <i [class]="item.icon"></i>
            <h6>{{ item.title }}</h6>
          </a>
        </div>
      </div>
  
    </div>
  </div>
  