import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.less'
})

export class HomepageComponent {
  items = [
    { link: '/home', title: 'Home', icon: 'bi bi-house-door' },
    { link: '/radio', title: 'Radios', icon: 'bi bi-music-note-beamed' },
    { link: 'http://home.konios.gr:8123', title: 'Home Assistant', icon: 'bi bi-toggles' },
    { link: 'https://www.ethnos.gr', title: 'Έθνος', icon: 'bi bi-newspaper' },
    { link: 'https://www.protothema.gr', title: 'Πρώτο Θέμα', icon: 'bi bi-newspaper' },
    { link: 'https://www.iefimerida.gr', title: 'iEfimerida', icon: 'bi bi-newspaper' },
    { link: 'https://gr.euronews.com', title: 'Euronews', icon: 'bi bi-newspaper' },
    { link: 'https://news.google.com/?tab=wn&hl=el&gl=GR&ceid=GR:el', title: 'Google News', icon: 'bi bi-newspaper' },
    { link: 'https://www.tovima.gr/category/science/', title: 'Το Βήμα', icon: 'bi bi-newspaper' },
    { link: 'https://www.msn.com/el-gr/news', title: 'MSN News', icon: 'bi bi-newspaper' },
    { link: 'https://gr.euronews.com/knowledge/sci-tech', title: 'sci-tech', icon: 'bi bi-newspaper' },
    { link: 'https://chat.openai.com/', title: 'Open AI', icon: 'bi bi-command' },
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = window.location.href.toLowerCase();

      if (currentUrl.endsWith("//radio.konios.gr/") || currentUrl.endsWith("http://radio.konios.gr")) {
        window.location.href = "http://home.konios.gr/radio";
      }

      if (currentUrl.endsWith("//homepage.konios.gr/") || currentUrl.endsWith("http://homepage.konios.gr")) {
        window.location.href = "http://home.konios.gr";
      }
    }
  }

}
