import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [
    CommonModule     
  ],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.less'
})



export class RadioComponent  implements OnInit
{
  stations: any = [];  
  currentStation: string = "0";

  constructor(
    @Inject(PLATFORM_ID) 
    private platformId: Object, private http:HttpClient) { }
  
    
  ngOnInit(): void {
    
    //default values before read db
    this.stations = [
      {"position":1,  "title":"FLY 104",              "url":"https://imagine2.radioca.st/;", "site":"http://live24.gr/radio/generic.jsp?sid=299#l24player=MusesPlayer","visible":1,"img":"","ID":53},
      {"position":2,  "title":"BEST RADIO 92.6",      "url":"http://best.live24.gr/best1222","site":"http://best926.gr","visible":1,"img":"","ID":32},
      {"position":3,  "title":"CITY FM 100",          "url":"http://eco.onestreaming.com:8173/;","site":"http://cityfm100.gr","visible":1,"img":"","ID":11},
      {"position":5,  "title":"HUNTER LOFI",          "url":"https://live.hunter.fm/lofi_normal","site":"https://hunter.fm","visible":1,"img":"","ID":2},
      {"position":6,  "title":"LOFI",                 "url":"https://www.youtube.com/watch?v=lP26UCnoH9s&list=PL5x6KwOIY0R0XokYD4GIZTB1NVkDeElnW&ab_channel=STEEZYASFUCK","site":"https://www.youtube.com/watch?v=lP26UCnoH9s&list=PL5x6KwOIY0R0XokYD4GIZTB1NVkDeElnW&ab_channel=STEEZYASFUCK","visible":1,"img":"","ID":37},
      {"position":7,  "title":"DEEP VIBES",           "url":"https://www.youtube.com/watch?v=Uj6shBUMZa4&t=852s&ab_channel=Gentleman","site":"https://www.youtube.com/watch?v=Uj6shBUMZa4&t=852s&ab_channel=Gentleman","visible":1,"img":"","ID":40},
      {"position":8,  "title":"CHILL RADIO",          "url":"https://www.youtube.com/watch?v=wAoWBypBLvU&ab_channel=ChilloutKingIbiza-Lounge%26ChillhouseMusicMix","site":"https://www.youtube.com/watch?v=wAoWBypBLvU&ab_channel=ChilloutKingIbiza-Lounge%26ChillhouseMusicMix","visible":1,"img":"","ID":5},
      {"position":10, "title":"LOFI PANDA",           "url":"https://stream-150.zeno.fm/umhxwwtke0hvv?zs=C04vrInYQIi0O5uJLL0rgA","site":"https://lofipandamusic.com/","visible":1,"img":"","ID":43},
      {"position":11, "title":"YOUTUBE",              "url":"https://www.youtube.com/playlist?list=PL5x6KwOIY0R3wdxyqZ5LjFpGOA_3hhcm6","site":"https://www.youtube.com/playlist?list=PL5x6KwOIY0R3wdxyqZ5LjFpGOA_3hhcm6","visible":1,"img":"","ID":48},
      {"position":12, "title":"SPOTIFY",              "url":"https://open.spotify.com","site":"https://open.spotify.com","visible":1,"img":"","ID":44}
    ];    

    if (isPlatformBrowser(this.platformId)) 
    {
      try 
      {
        this.http.get('/api/get_radios').subscribe((data) => {
          this.stations = data;
        });
      } 
      catch(error) {}
      
      this.currentStation = localStorage.getItem('radio-LastStation') + "";

      /*
      if (this.currentStation != null) {
        if (!this.stations[parseInt(this.currentStation)].url.includes('youtube') && !this.stations[parseInt(this.currentStation)].url.includes('spotify')) {
          this.playAudio( parseInt(this.currentStation));
        }
      } 
      */
        
    }


  }

  playStation(index: number): void {
    if (this.stations.length === 0) return;
    if (index >= 0 && index < this.stations.length) {
      this.setCurrentStation(index);
    }
  }

  setCurrentStation(index: number): void {
    this.currentStation = index.toString();
    localStorage.setItem('radio-LastStation', index.toString());
    this.playAudio(index);
  }

  playAudio(index: number): void {
    const audio = document.querySelector('audio');
    try {
      if (audio) {        
        if (this.stations[index].url.includes('youtube') || this.stations[index].url.includes('spotify')) {
          audio.muted = true;
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = this.stations[index].url;
          }
        } else {
          audio.muted = false;
          audio.src = this.stations[index].url;
          audio.play();
        }
      }
    } catch (e) {
      //console.log(e);
    }
  }

  mute(): void {
    const audio = document.querySelector('audio');
    if (audio) audio.pause();
  }

  

}
