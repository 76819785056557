import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HomepageComponent } from './homepage/homepage.component';
import { RadioComponent } from './radio/radio.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'homepage', component: HomepageComponent },
  { path: 'radio', component: RadioComponent },  
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  //{ path: '**', component: HomepageComponent }
];
