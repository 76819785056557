<div class="RadioForm">
    <section id="SectionBody">
      <section id="SectionList">
        <div id="List">
          <h1 id="RadioStationsHeader">Radio Stations</h1>
          
          <a id="home" href="/home">home</a>
          <a id="ha" href="/homepage">homepage</a>
          <a id="mute" (click)="mute()">mute</a>
          <br />
          <br />
          <span *ngFor="let item of stations; let i = index" [id]="'span_' + i">
            <a [ngStyle]="{'color': (i.toString() === currentStation) ? 'red' : 'white'}" [id]="'lnk' + i" [title]="item.title" (click)="playStation(i)">{{ item.title }}</a>
            <a *ngIf="item.site" [title]="item.site" target="_blank" [href]="item.site"> >></a>
            <br />
          </span>
        </div>
        <audio id="Audio" autoplay muted="muted">
          <source />
        </audio>
      </section>
    </section>
  </div>
  